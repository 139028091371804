import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TopScroller = () => {
  const { hash, pathname } = useLocation();

  useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0);
    }
  }, [hash, pathname]);
  return null;
};
export default TopScroller;

import React from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer d-flex justify-content-center pb-4 mt-5">
      <div className="container py-5 d-flex flex-row justify-content-center">
        <div className="footer-link">
          <Link to="/kontakt">Kontakt</Link>
        </div>
        <div className="footer-link">
          <Link to="/datenschutz">Datenschutz</Link>
        </div>
        <div className="footer-link">
          <Link to="/impressum">Impressum</Link>
        </div>
        <div className="footer-link">
          <a href="https://www.bellfoodgroup.com/de/bell-food-group/corporate-governance/#compliance" target="_blank">Compliance Bell Food Group</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;

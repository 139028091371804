import React from 'react';
import './ResponsiveImage.scss';

const ResponsiveImage = ({ imgSm, imgMd, alt, imgClassName, children }) => {
  return (
    <div className="responsive-image-wrapper">
      <picture>
        {<source srcSet={imgSm} media="(max-width: 576px)" />}
        <source srcSet={imgMd} />
        <img
          className={
            'responsive-image' + (imgClassName ? ' ' + imgClassName : '')
          }
          src={imgMd}
          alt={alt}
        />
      </picture>
      {children}
    </div>
  );
};

export default ResponsiveImage;

import React, { useCallback, useEffect } from 'react';
import './InfoTile.scss';
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage';

const InfoTile = ({ isActive, tileContent, index, onClick }) => {
  const isRightFloater = index % 2 === 0;

  const handleTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToTarget = useCallback(() => {
    let scrollTargetOffset = window.innerWidth < 1200 ? 2 : 3;

    const el = document.getElementById(
      'tile-body-jumpmark-' + (index - scrollTargetOffset)
    );

    if (el && isActive) {
      el.scrollIntoView();
    }
  }, [index, isActive]);

  useEffect(() => {
    const tileBody = document.getElementById('tile-body-' + index);
    if (tileBody) {
      tileBody.addEventListener('transitionstart', scrollToTarget);
      tileBody.addEventListener('transitionend', scrollToTarget);
    }
    return () => {
      if (tileBody) {
        tileBody.removeEventListener('transitionstart', scrollToTarget);
        tileBody.removeEventListener('transitionend', scrollToTarget);
      }
    };
  });

  const handleClick = useCallback(() => {
    if (!isActive) {
      onClick(index);
    }
  }, [index, isActive, onClick]);

  return (
    <div
      className={`info-tile-wrapper info-tile-wrapper-${index} col-6 col-xl-4 p-0 position-relative info-tile info-tile-${
        index + 1
      } ${isActive ? 'is-active' : ''}`}
      onClick={handleClick}
    >
      {/* Tile Header */}
      <div className="d-flex flex-column">
        <div className="info-tile-hover">
          <ResponsiveImage
            imgClassName={'w-100 p-3'}
            imgSm={require(`../../assets/images/Menu_Desktop_${index + 1}.png`)}
            imgMd={require(`../../assets/images/Menu_Desktop_${index + 1}.png`)}
            alt={tileContent.tileAlt}
          >
            <div className={`info-tile-expand${isActive ? ' is-active' : ''}`}>
              <img
                src={require('../../assets/images/Pfeil_links.png')}
                alt="Aufklapp-Icon"
              />
            </div>
          </ResponsiveImage>
          <h2 className="tile-title mt-3">{tileContent.tileTitle}</h2>
        </div>
        <div id={'tile-body-jumpmark-' + index} />
      </div>

      {/* Tile Body */}
      <div
        id={'tile-body-' + index}
        className={`container d-lg-flex position-relative tile-body ${
          isActive ? 'is-open' : ''
        }`}
      >
        <div className="pt-4 d-flex flex-column d-md-block w-100">
          <div>
            <div
              className={
                'd-flex ' +
                'flex-column-reverse ' +
                (isRightFloater ? 'flex-lg-row-reverse' : 'flex-lg-row')
              }
            >
              <div
                className={
                  tileContent.imgNarrow ? 'col-lg-4 tile-narrow' : 'col-lg-8'
                }
              >
                <ResponsiveImage
                  imgSm={require(`../../assets/images/Jalousie_Mobile_${
                    index + 1
                  }.png`)}
                  imgMd={require(`../../assets/images/Jalousie_Desktop_${
                    index + 1
                  }.png`)}
                  alt={tileContent.bodyAlt}
                />
              </div>
              <div
                className={
                  'tile-body-content ' +
                  (tileContent.imgNarrow ? 'col-lg-8 ' : 'col-lg-4 ') +
                  (isRightFloater ? 'pe-lg-4' : 'ps-lg-4')
                }
              >
                <span className="like-h1 tile-content-title">
                  {tileContent.bodyTitle}
                </span>
                {tileContent.bodyText}
              </div>
            </div>
            <div
              className="up-arrow d-flex justify-content-center mb-4 d-lg-none"
              onClick={handleTop}
            >
              <img
                src={require('../../assets/images/Pfeil_links.png')}
                alt="Pfeil nach oben"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoTile;

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Imprint from './pages/Imprint';
import DataProtection from './pages/DataProtection';
import Home from './pages/Home';
import TopScroller from './components/TopScroller/TopScroller';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Contact from './pages/Contact';

const Main = () => {
  return (
    <>
      <BrowserRouter>
        <div className="main">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/impressum" element={<Imprint />} />
            <Route path="/datenschutz" element={<DataProtection />} />
            <Route path="/kontakt" element={<Contact />} />
            <Route path="/kontakt/erfolg" element={<Contact success="true" />} />
            <Route path="*" element={<Home />} />
          </Routes>
          <TopScroller />
          <Footer />
        </div>
      </BrowserRouter>
    </>
  );
};

export default Main;

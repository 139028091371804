import React from 'react';
import logo from '../../assets/images/Natuco_Logo_RGB.png';
import leaves from '../../assets/images/Blaetter_Header.png';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import './Header.scss';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <div className="header d-flex justify-content-center">
      <div className="row justify-content-around container">
        <div className="col-lg-4 text-center logo-wrapper">
          <Link to="/">
            <img className="logo" src={logo} alt="NATUCO Logo" />
          </Link>
        </div>
        <div className="d-none d-lg-block col-lg-1" />
        <div className="d-flex col-12 col-lg-6 justify-items-center">
          <div className="slogan-wrapper d-flex flex-column justify-content-center">
            <div className="company-slogan company-slogan-1">
              <h1>Lasst uns</h1>
            </div>
            <div className="company-slogan company-slogan-2">
              <h1>besser essen.</h1>
            </div>
            <img
              className="leaves d-block"
              src={leaves}
              alt="Bewegte Blätter"
            />
          </div>
        </div>
        <div className="burgermenu-container col-12 col-lg-1">
          <BurgerMenu />
        </div>
      </div>
    </div>
  );
};

export default Header;

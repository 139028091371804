import React from 'react';
import './pages.scss';

const DataProtection = () => {
  return (
    <div className="content-page container flex-grow-1 mt-5">
      <div className="row">
        <h1>Datenschutzerklärung</h1>
        <hr className="mb-5" />
        <h4>1.Datenschutzerklärung der Hügli Nahrungsmittel GmbH</h4>
        <p className="mb-4">
          Verantwortlicher im Sinne der Verordnung (EU) 2016/679 des Europäischen Parlaments
          und des Rates vom 27. April 2016 zum Schutz natürlicher Personen bei der Verarbeitung
          personenbezogener Daten, zum freien Datenverkehr und zur Aufhebung der Richtlinie
          95/46/EG („DSGVO“) bzw. des Schweizer Bundesgesetzes über den Datenschutz (Nr. 235.1 – „DSG“)  ist:
        </p>
        <br />
        <p>
          <span className="fw-bold">Hügli Nahrungsmittel GmbH, Güttinger Strasse 23, DE-78315 Radolfzell</span>
        </p>
        <p>
          <span className="fw-bold">Telefon:</span>
          <a href="tel:+4977328070"> +49 7732 8070</a>
        </p>
        <br />
        <p>
          <span className="fw-bold">E-Mail:</span>
          <a href="mailto:datenschutz@huegli.de"> datenschutz@huegli.de</a>
        </p>
        <h4>[1. Umfang der Verarbeitung personenbezogener Daten]</h4>
        <p>
          Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich
          nur, soweit dies zur Bereitstellung einer funktionsfähigen Website
          sowie unserer Inhalte und Leistungen erforderlich ist und nur bei
          Vorliegen einer der in der Datenschutz-Grundverordnung („DS-GVO“)
          genannten Rechtsgrundlagen.
        </p>
        <h4>[2. Beschreibung und Umfang der Datenverarbeitung]</h4>
        <p>
          Bei jedem Aufruf unserer Internetseite erfasst unser System
          automatisiert Daten und Informationen vom Computersystem des
          aufrufenden Rechners. Folgende Daten werden hierbei erhoben:
        </p>
        <p>
          Informationen über den Browsertyp (und dessen Version), das
          Betriebssystem, den Internetserviceprovider, die IP-Adresse, Datum und
          Uhrzeit des Zugriffs, Internetseiten, von denen das System des Nutzers
          auf unsere Internetseite gelangt, Internetseiten, die vom System des
          Nutzers über unsere Internetseite aufgerufen werden, Clickstreams;
        </p>
        <p>
          Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert.
          Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen
          Daten des Nutzers findet nicht statt.
        </p>
        <h4>[3. Zweck der Datenverarbeitung]</h4>
        <p>
          Die vorübergehende Speicherung der IP-Adresse durch das System ist
          notwendig, um eine Auslieferung der Website an den Rechner des Nutzers
          zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer
          der Sitzung gespeichert bleiben.
        </p>
        <p>
          Die Speicherung in Logfiles erfolgt,
          um die Funktionsfähigkeit der Website sicherzustellen. Zudem dienen
          uns die Daten zur Optimierung der Website und zur Sicherstellung der
          Sicherheit unserer informationstechnischen Systeme. Eine Auswertung
          der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht
          statt.
        </p>
        <h4>[4. Rechtsgrundlage für die Verarbeitung personenbezogener Daten]</h4>
        <p>
          Zum Zwecke dieses Internetauftrittes gründet sich unsere
          Datenverarbeitung auf folgende Rechtsgrundlagen:
        </p>
        <p>
          Rechtsgrundlage für die vorübergehende Speicherung der Daten und der Logfiles
          ist Art. 6 Abs. 1 lit. f DS-GVO, da die Speicherung zur Wahrung eines
          berechtigten Interesses unseres Unternehmens oder eines Dritten
          erforderlich ist und die Interessen, Grundrechte und Grundfreiheiten
          des Betroffenen das erstgenannte Interesse nicht überwiegen.
        </p>
        <h4>[5. Dauer der Speicherung]</h4>
        <p>
          Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
          ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung
          der Daten zur Bereitstellung der Website ist dies der Fall, wenn die
          jeweilige Sitzung beendet ist.
        </p>
        <p>
          Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens
          7 Monate der Fall. Eine darüberhinausgehende Speicherung ist möglich.
          In diesem Fall werden die IP-Adressen der Nutzer gelöscht oder verfremdet,
          sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.
        </p>
        <h4>[6. Widerspruchs- und Beseitigungsmöglichkeit]</h4>
        <p>
          Die Erfassung der Daten zur Bereitstellung der Website und die
          Speicherung der Daten in Logfiles ist für den Betrieb der
          Internetseite zwingend erforderlich. Es besteht folglich seitens des
          Nutzers keine Widerspruchsmöglichkeit. Will der Nutzer die künftige
          und weitergehende Speicherung verhindern, muss er die Internetseite
          verlassen und künftig auf einen Aufruf dieser Internetseite
          verzichten.
        </p>
        <h4>[7. Verwendung von Cookies]</h4>
        <p>
          Unsere Internetseite verwendet Cookies. Bei Cookies handelt es sich um Textdateien,
          die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem des Nutzers
          gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem
          Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine
          charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers
          beim erneuten Aufrufen der Website ermöglicht.
        </p>
        <p>
          Der Zweck der Verwendung von Cookies ist, die Nutzung von Websites für die Nutzer
          zu vereinfachen. Einige Funktionen unserer Internetseite können ohne den Einsatz
          von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser
          auch nach einem Seitenwechsel wiedererkannt wird.
        </p>
        <p>
          Für folgende Anwendungen benötigen wir Cookies:
        </p>
        <p>Übernahme von Cookie Bestätigung</p>
        <p>In den Cookies werden dabei folgende Daten gespeichert und übermittelt:</p>
        <p>Cookies akzeptiert</p>
        <p>
          Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur
          Erstellung von Nutzerprofilen verwendet.
        </p>
        <p>
          Neben den technisch notwendigen Cookies setzen wir auch technisch nicht notwendige
          Cookies ein, die eine Analyse des Surfverhaltens der Nutzer ermöglichen. Auf diese
          Weise werden folgende Daten verarbeitet:
        </p>
        <p>
          Eingegebene Suchbegriffe, Häufigkeit von Seitenaufrufen, Inanspruchnahme von
          Website-Funktionen, Anonymisierte IP
        </p>
        <p>
          Die Verwendung der Analyse-Cookies erfolgt zu dem Zweck, die Qualität unserer
          Website und ihre Inhalte zu verbessern. Durch die Analyse-Cookies erfahren wir,
          wie die Website genutzt wird und können so unser Angebot stetig optimieren.
        </p>
        <p>
          Beim Aufruf unserer Website wird der Nutzer über die Verwendung von Cookies zu
          Analysezwecken informiert und seine Einwilligung zur Verarbeitung der in diesem
          Zusammenhang verwendeten personenbezogenen Daten eingeholt. In diesem Zusammenhang
          erfolgt auch ein Hinweis auf diese Datenschutzerklärung.
        </p>
        <p>
          Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer
          Seite übermittelt. Daher haben Sie als Nutzer auch die volle Kontrolle über die
          Verwendung von Cookies. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser
          können Sie die Übertragung von Cookies deaktivieren oder einschränken. Bereits gespeicherte
          Cookies können jederzeit durch den Nutzer über seinen Webbrowser gelöscht werden.
          Dies kann auch automatisiert erfolgen.
        </p>
        <p>
          Werden Cookies für unsere Website deaktiviert, können möglicherweise nicht mehr
          alle Funktionen der Website vollumfänglich genutzt werden.
        </p>
        <p>Cookie Einstellungen</p>
        <p>
          Alternativ verhindern Sie mit einem Klick auf diesen Link, dass Google Analytics
          innerhalb dieser Website Daten über Sie erfasst. Mit dem Klick auf obigen Link
          laden Sie ein „Opt-Out-Cookie“ herunter. Ihr Browser muss die Speicherung von
          Cookies also hierzu grundsätzlich erlauben. Löschen Sie Ihre Cookies regelmäßig,
          ist ein erneuter Klick auf den Link bei jedem Besuch dieser Website vonnöten.
        </p>
        <h4>[8. Newsletter]</h4>
        <p>
          Auf unserer Internetseite besteht die Möglichkeit, einen kostenfreien Newsletter
          zu abonnieren. Die Möglichkeit, Newsletter zu erhalten, besteht auch im Rahmen
          eines Bestellvorgangs und einer Kontaktaufnahme auf unserer Internetseite.
        </p>
        <p>
          Dabei werden bei der Anmeldung zum Newsletter folgende Daten aus der
          Eingabemaske an uns übermittelt:
        </p>
        <p>
          Anfragekategorie, Anrede, Vorname, Nachname, Firma, Strasse, PLZ, Ort,
          Land, Telefon, Fax, E-Mail, Sprache, Datum und Uhrzeit der Registrierung,
          Zustimmung zur Datenschutzerklärung
        </p>
        <p>
          Rechtsgrundlage für die Verarbeitung der Daten nach Anmeldung zum Newsletters
          durch den Nutzer ist bei Vorliegen einer Einwilligung des Nutzers
          Art. 6 Abs. 1 lit. a DS-GVO (Zustimmung des Betroffenen).
        </p>
        <p>
          Es erfolgt im Zusammenhang mit der Datenverarbeitung für den Versand von
          Newslettern keine Weitergabe der Daten an Dritte, mit Ausnahme im Rahmen
          der Hügli Group, d.h. der Unternehmensgruppe, zu der wir gehören.
          Die Weiterleitung erfolgt zum Zwecke der Speicherung in zentralen
          Datenzentren der Hügli Group und zur Erstellung von Analysen und
          Strategien für die unternehmerische Tätigkeit der Hügli Group. Dabei
          kann eine Übertragung in ein anderes Land der EU oder des EWR sowie
          in die Schweiz erfolgen.
        </p>
        <p>
          Die Erhebung der E-Mail-Adresse des Nutzers dient dazu, den Newsletter
          zuzustellen. Die Erhebung sonstiger personenbezogener Daten im Rahmen
          des Anmeldevorgangs dient dazu, einen Missbrauch der Dienste oder der
          verwendeten E-Mail-Adresse zu verhindern und eine Übereinstimmung mit
          der DS-GVO zu dokumentieren.
        </p>
        <p>
          Die personenbezogenen Daten des Nutzers im Zusammenhang mit dem Newsletter
          werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung
          nicht mehr erforderlich sind, d.h. der Nutzer den Newsletter abbestellt.
        </p>
        <p>
          Das Abonnement des Newsletters kann durch den betroffenen Nutzer jederzeit
          gekündigt werden. Zu diesem Zweck findet sich in jedem Newsletter ein
          entsprechender Link zu einer Eingabemaske. Hierdurch wird ebenfalls ein
          Widerruf der Einwilligung der Speicherung der während des Anmeldevorgangs
          erhobenen personenbezogenen Daten ermöglicht.
        </p>
        <h4>[9. Kontaktformular, Unterlagen Bestellen Formular, Telefon- und E-Mail-Kontakt]</h4>
        <p>
          Auf unserer Internetseite ist ein Kontaktformular vorhanden, welches
          für die elektronische Kontaktaufnahme genutzt werden kann. Nimmt ein Nutzer
          diese Möglichkeit wahr, so werden die in der Eingabemaske eingegebenen Daten
          an uns übermittelt und gespeichert. Diese Daten sind:
        </p>
        <p>
          Ich bin (Art der Person), Anrede, Vorname, Nachname, Firma, Strasse, PLZ, Ort,
          Land, Telefon, Fax, E-Mail, Nachricht, Datum und Uhrzeit der Registrierung,
          Zustimmung zur Datenschutzerklärung.
        </p>
        <p>
          Alternativ ist eine Kontaktaufnahme über die bereitgestellte E-Mail-Adresse
          oder telefonisch möglich. In diesem Fall werden die mit der E-Mail oder
          telefonisch übermittelten personenbezogenen Daten des Nutzers gespeichert,
          insbesondere Email-Adresse bzw. Telefonnummer.
        </p>
        <p>
          Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte, mit
          Ausnahme im Rahmen der Hügli Group. Die Weiterleitung erfolgt zum Zwecke der
          Speicherung in zentralen Datenzentren der Hügli Group und zur Erstellung von
          Analysen und Strategien für die unternehmerische Tätigkeit der Hügli Group.
          Dabei kann eine Übertragung in ein anderes Land der EU oder des EWR sowie
          in die Schweiz erfolgen.
        </p>
        <p>
          Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer
          Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DS-GVO.
        </p>
        <p>
          Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer Übersendung
          einer E-Mail übermittelt werden, ist Art. 6 Abs. 1 lit. f DS-GVO. Zielt der
          E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so ist zusätzliche
          Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DS-GVO.
        </p>
        <p>
          Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske dient uns
          allein zur Bearbeitung der Kontaktaufnahme. Im Falle einer Kontaktaufnahme
          per E-Mail liegt hieran auch das erforderliche berechtigte Interesse an der
          Verarbeitung der Daten.
        </p>
        <p>
          Die sonstigen während des Absendevorgangs verarbeiteten personenbezogenen Daten
          dienen dazu, einen Missbrauch des Kontaktformulars zu verhindern und die Sicherheit
          unserer informationstechnischen Systeme sicherzustellen.
        </p>
        <p>
          Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung
          nicht mehr erforderlich sind. Für die personenbezogenen Daten aus der Eingabemaske
          des Kontaktformulars und diejenigen, die per E-Mail übersandt wurden, ist dies dann
          der Fall, wenn die jeweilige Konversation mit dem Nutzer beendet ist. Beendet ist
          die Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass der
          betroffene Sachverhalt abschließend geklärt ist.
        </p>
        <p>
          Die während des Absendevorgangs zusätzlich erhobenen personenbezogenen Daten werden
          spätestens nach einer Frist von sieben Tagen gelöscht.
        </p>
        <p>
          Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur Verarbeitung der
          personenbezogenen Daten zu widerrufen. Nimmt der Nutzer per E-Mail Kontakt mit uns
          auf, so kann er der Speicherung seiner personenbezogenen Daten jederzeit durch Email
          an <a href="mailto:info@huegli.com">info@huegli.com</a> widersprechen. In einem solchen
          Fall kann die Konversation nicht fortgeführt werden. Ist aufgrund der bereits erfolgten
          Kommunikation eine Handlung erfolgt, die für uns oder den Betroffenen rechtsverbindliche
          Folgen auslöst, sind wir berechtigt, ein Löschungsbegehren abzulehnen, sofern wir die
          Datenverarbeitung auf einer der in Artikel 6 DS-GVO genannten Rechtsgrundlagen stützen können.
        </p>
        <p>
          Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert wurden,
          werden in diesem Fall gelöscht.
        </p>
        <h4>[10. Rechte des Nutzers]</h4>
        <p className="mb-4">
          Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DS-GVO und
          es stehen Ihnen folgende Rechte gegenüber uns als dem Verantwortlichen zu:
        </p>
        <p>
          <span className="fw-bold">Auskunftsrecht</span>
        </p>
        <p>
          Sie können von uns eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie betreffen,
          von uns verarbeitet werden. Liegt eine solche Verarbeitung vor, können Sie von uns über folgende
          Informationen Auskunft verlangen:
        </p>
        <ul className="ms-4">
          <li>die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</li>
          <li>die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</li>
          <li>
            die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie
            betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;
          </li>
          <li>
            die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder,
            falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;
          </li>
          <li>
            das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden
            personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung
            durch uns oder eines Widerspruchsrechts gegen diese Verarbeitung;
          </li>
          <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
          <li>
            alle verfügbaren Informationen über die Herkunft der Daten, wenn die
            personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;
          </li>
          <li>
            das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling
            gemäß Art. 22 Abs. 1 und 4 DS-GVO und – zumindest in diesen Fällen – aussagekräftige
            Informationen über die involvierte Logik sowie die Tragweite und die angestrebten
            Auswirkungen einer derartigen Verarbeitung für die betroffene Person.
          </li>
        </ul>
        <p className="mb-4">
          Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden
          personenbezogenen Daten in ein Drittland oder an eine internationale Organisation
          übermittelt werden. In diesem Zusammenhang können Sie verlangen, über die geeigneten
          Garantien gem. Art. 46 DS-GVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.
        </p>
        <p>
          <span className="fw-bold">Recht auf Berichtigung</span>
        </p>
        <p className="mb-4">
          Sie haben ein Recht auf Berichtigung und/oder Vervollständigung uns gegenüber,
          sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig
          oder unvollständig sind. Wir haben die Berichtigung unverzüglich vorzunehmen,
          sobald Sie uns mitgeteilt haben, wie richtigerweise diese Daten erfasst werden müssen.
        </p>
        <p>
          <span className="fw-bold">Recht auf Einschränkung der Verarbeitung</span>
        </p>
        <p>
          Unter den folgenden Voraussetzungen können Sie die Einschränkung
          der Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
        </p>
        <ul className="ms-4">
          <li>
            wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine
            Dauer bestreiten, die es uns ermöglicht, die Richtigkeit der
            personenbezogenen Daten zu überprüfen;
          </li>
          <li>
            die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen
            Daten ablehnen und stattdessen die Einschränkung der Nutzung der
            personenbezogenen Daten verlangen;
          </li>
          <li>
            wir die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger
            benötigen, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung
            von Rechtsansprüchen benötigen, oder
          </li>
          <li>
            wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DS-GVO eingelegt
            haben und noch nicht feststeht, ob die berechtigten Gründe unsererseits gegenüber
            Ihren Gründen überwiegen.
          </li>
        </ul>
        <p>
          Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt,
          dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung
          oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder
          zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder
          aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines
          Mitgliedstaats verarbeitet werden.
        </p>
        <p className="mb-4">
          Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt,
          werden Sie von uns unterrichtet bevor die Einschränkung aufgehoben wird.
        </p>
        <p>
          <span className="fw-bold">Recht auf Löschung</span>
        </p>
        <p>
          Sie können von uns verlangen, dass die Sie betreffenden personenbezogenen Daten
          unverzüglich gelöscht werden, und wir sind dann verpflichtet, diese Daten unverzüglich
          zu löschen, sofern einer der folgenden Gründe zutrifft:
        </p>
        <ul className="ms-4">
          <li>
            Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie
            erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
          </li>
          <li>
            Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 lit. a
            oder Art. 9 Abs. 2 lit. a DS-GVO stützte, und es fehlt an einer anderweitigen
            Rechtsgrundlage für die Verarbeitung.
          </li>
          <li>
            Sie legen gem. Art. 21 Abs. 1 DS-GVO Widerspruch gegen die Verarbeitung ein und es liegen
            keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen
            gem. Art. 21 Abs. 2 DS-GVO Widerspruch gegen die Verarbeitung ein.
          </li>
          <li>Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
          <li>
            Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer
            rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
            erforderlich, dem wir unterliegen.
          </li>
          <li>
            Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene
            Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DS-GVO erhoben.</li>
        </ul>
        <p>
          Haben wir die Sie betreffenden personenbezogenen Daten öffentlich gemacht und
          sind wir gem. Art. 17 Abs. 1 DS-GVO zu deren Löschung verpflichtet, so treffen
          wir unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten
          angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche,
          die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als
          betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten
          oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.
        </p>
        <p>Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist</p>
        <ul className="ms-4">
          <li>zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</li>
          <li>
            zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht
            der Union oder der Mitgliedstaaten, dem wir unterliegen, erfordert, oder zur Wahrnehmung
            einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt
            erfolgt, die uns übertragen wurde;
          </li>
          <li>
            aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit
            gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DS-GVO;
          </li>
          <li>
            für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische
            Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DS-GVO, soweit das unter
            Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung
            unmöglich macht oder ernsthaft beeinträchtigt, oder
          </li>
          <li>zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</li>
        </ul>
        <p className="mb-4">
          Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung uns
          gegenüber geltend gemacht, sind wir verpflichtet, allen Empfängern, denen die Sie
          betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder
          Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn,
          dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden.
          Ihnen steht uns gegenüber das Recht zu, über diese Empfänger unterrichtet zu werden.
        </p>
        <p>
          <span className="fw-bold">Recht auf Datenübertragbarkeit</span>
        </p>
        <p>
          Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns
          bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format
          zu erhalten. Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen
          ohne Behinderung durch uns, dem die personenbezogenen Daten bereitgestellt wurden,
          zu übermitteln, sofern
        </p>
        <ul className="ms-4">
          <li>
            die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DS-GVO oder
            Art. 9 Abs. 2 lit. a DS-GVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DS-GVO beruht und
          </li>
          <li>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
        </ul>
        <p>
          In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden
          personenbezogenen Daten direkt von uns einem anderen Verantwortlichen übermittelt werden,
          soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch
          nicht beeinträchtigt werden.
        </p>
        <p className="mb-4">
          Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener
          Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen
          Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die uns übertragen wurde.
        </p>
        <p>
          <span className="fw-bold">Widerspruchsrecht</span>
        </p>
        <p>
          Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit
          gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von
          Art. 6 Abs. 1 lit. e oder f DS-GVO erfolgt, Widerspruch einzulegen; dies gilt auch für
          ein auf diese Bestimmungen gestütztes Profiling.
        </p>
        <p>
          Wir verarbeiten die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn,
          wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen,
          Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung
          oder Verteidigung von Rechtsansprüchen.
        </p>
        <p>
          Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben,
          haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden
          personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das
          Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
        </p>
        <p>
          Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie
          betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.
        </p>
        <p className="mb-4">
          Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der
          Informationsgesellschaft Ihr Widerspruchsrecht mittels automatisierter Verfahren
          auszuüben, bei denen technische Spezifikationen verwendet werden.
        </p>
        <p>
          <span className="fw-bold">Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</span>
        </p>
        <p className="mb-4">
          Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen.
          Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung
          bis zum Widerruf erfolgten Verarbeitung nicht berührt.
        </p>
        <p>
          <span className="fw-bold">Automatisierte Entscheidung im Einzelfall einschließlich Profiling</span>
        </p>
        <p>
          Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung
          – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen
          gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt.
          Dies gilt nicht, wenn die Entscheidung
        </p>
        <ul className="ms-4">
          <li>für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und uns erforderlich ist,</li>
          <li>
            aufgrund von Rechtsvorschriften der Europäischen Union oder der Mitgliedstaaten, denen wir
            unterliegen, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung
            Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder
          </li>
          <li>mit Ihrer ausdrücklichen Einwilligung erfolgt.</li>
        </ul>
        <p>
          Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener
          Daten nach Art. 9 Abs. 1 DS-GVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g DS-GVO
          gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten
          Interessen getroffen wurden.</p>
        <p className="mb-4">
          Hinsichtlich der genannten Fälle treffen wir angemessene Maßnahmen, um die Rechte und
          Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf
          Erwirkung des Eingreifens einer Person unsererseits, auf Darlegung des eigenen Standpunkts
          und auf Anfechtung der Entscheidung gehört.
        </p>
        <p>
          <span className="fw-bold">Recht auf Beschwerde bei einer Aufsichtsbehörde</span>
        </p>
        <p>
          Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs
          steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem
          Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
          Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
          personenbezogenen Daten gegen die DS-GVO verstößt.
        </p>
        <p>
          Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den
          Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich
          der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DS-GVO.
        </p>
        <h4>[11. Nutzung von Social-Media-Plugins]</h4>
        <p>
          <span className="fw-bold">a) FACEBOOK</span>
        </p>
        <p>
          Auf unserer Website werden sogenannte Social Plugins („Plugins“) des sozialen
          Netzwerkes Facebook verwendet, das von der Meta Platforms Ireland Limited, 4 Grand
          Canal Square, Grand Canal Harbour, Dublin 2, Ireland („Facebook“) betrieben wird.
          Die Plugins sind mit einem Facebook-Logo oder dem Zusatz „Soziales Plug-in von
          Facebook“ bzw. „Facebook Social Plugin“ gekennzeichnet. Eine Übersicht über die Facebook
          Plugins und deren Aussehen finden Sie hier: <br />
          <a href="https://developers.facebook.com/docs/plugins">https://developers.facebook.com/docs/plugins</a>
        </p>
        <p>
          Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enthält, stellt
          Ihr Browser eine direkte Verbindung zu den Servern von Facebook her. Der Inhalt des Plugins
          wird von Facebook direkt an Ihren Browser übermittelt und in die Seite eingebunden. Durch
          diese Einbindung erhält Facebook die Information, dass Ihr Browser die entsprechende Seite
          unseres Webauftritts aufgerufen hat, auch wenn Sie kein Facebook-Profil besitzen oder gerade
          nicht bei Facebook eingeloggt sind. Diese Information (einschließlich Ihrer IP-Adresse) wird
          von Ihrem Browser direkt an einen Server von Facebook in die USA übermittelt und dort gespeichert.
        </p>
        <p>
          Sind Sie bei Facebook eingeloggt, kann Facebook den Besuch unserer Website Ihrem Facebook-Profil
          unmittelbar zuordnen. Wenn Sie mit den Plugins interagieren, zum Beispiel den „Gefällt mir“-Button
          betätigen oder einen Kommentar abgeben, wird diese Information ebenfalls direkt an einen Server
          von Facebook übermittelt und dort gespeichert. Die Informationen werden außerdem auf Ihrem
          Facebook- Profil veröffentlicht und Ihren Facebook-Freunden angezeigt.
        </p>
        <p>
          Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch
          Facebook sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer
          Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen von Facebook:
          <a href="http://www.facebook.com/policy.php">http://www.facebook.com/policy.php</a>
        </p>
        <p className="mb-4">
          Wenn Sie nicht möchten, dass Facebook die über unseren Webauftritt gesammelten Daten unmittelbar
          Ihrem Facebook-Profil zuordnet, müssen Sie sich vor Ihrem Besuch unserer Website bei Facebook
          ausloggen. Sie können das Laden der Facebook Plugins auch mit Add-Ons für Ihren Browser komplett
          verhindern. Diese Plugins finden Sie über eine Internetsuche; eine Übersicht mit den bekanntesten
          Browsern finden Sie bspw. hier: <br />
          <a href="http://www.netzstrategen.com/sagen/facebook-plugin-blocker/">http://www.netzstrategen.com/sagen/facebook-plugin-blocker/</a>
        </p>
        <p>
          <span className="fw-bold">b) GOOGLE+</span>
        </p>
        <p>
          Auf unserer Website werden sogenannte Social Plugins („Plugins“) des sozialen Netzwerkes Google+
          verwendet, das von der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043,
          USA („Google“) betrieben wird. Die Plugins sind z. B. an Buttons mit dem Zeichen „+1“ auf
          weißem oder farbigem Hintergrund erkennbar. Eine Übersicht über die Google Plugins und deren
          Aussehen finden Sie hier: <br />
          <a href="https://developers.google.com/+/plugins">https://developers.google.com/+/plugins</a>
        </p>
        <p>
          Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enthält, stellt
          Ihr Browser eine direkte Verbindung zu den Servern von Google her. Der Inhalt des Plugins
          wird von Google direkt an Ihren Browser übermittelt und in die Seite eingebunden. Durch die
          Einbindung erhält Google die Information, dass Ihr Browser die entsprechende Seite unseres
          Webauftritts aufgerufen hat, auch wenn Sie kein Profil bei Google+ besitzen oder gerade
          nicht bei Google+ eingeloggt sind.
        </p>
        <p>
          Diese Information (einschließlich Ihrer IP-Adresse) wird von Ihrem Browser direkt an einen
          Server von Google in die USA übermittelt und dort gespeichert. Sind Sie bei Google+ eingeloggt,
          kann Google den Besuch unserer Website Ihrem Google+ Profil unmittelbar zuordnen. Wenn Sie mit
          den Plugins interagieren, zum Beispiel den „+1“-Button betätigen, wird die entsprechende Information
          ebenfalls direkt an einen Server von Google übermittelt und dort gespeichert. Die Informationen werden
          außerdem auf Google+ veröffentlicht und dort Ihren Kontakten angezeigt.
        </p>
        <p>
          Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Google
          sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen
          Sie bitte den Datenschutzhinweisen von Google:
          <a href="http://www.google.com/intl/de/+/policy/+1button.html">http://www.google.com/intl/de/+/policy/+1button.html</a>
        </p>
        <p className="mb-4">
          Wenn Sie nicht möchten, dass Google die über unseren Webauftritt gesammelten Daten unmittelbar Ihrem
          Profil auf Google+ zuordnet, müssen Sie sich vor Ihrem Besuch unserer Website bei Google+ ausloggen.
          Sie können das Laden der Google Plugins auch mit Add-Ons für Ihren Browser komplett verhindern, z. B.
          mit dem Skript-Blocker „NoScript“ (<a href="http://noscript.net/">http://noscript.net/</a>).
        </p>
        <p>
          <span className="fw-bold">c) TWITTER</span>
        </p>
        <p>
          Auf unserer Website werden sogenannte Social Plugins („Plugins“) des Mikroblogging-Dienstes Twitter
          verwendet, der von der Twitter Inc., 1355 Market St, Suite 900, San Francisco, CA 94103, USA („Twitter“)
          betrieben wird. Die Plugins sind mit einem Twitter-Logo beispielsweise in Form eines blauen „Twitter- Vogels“
          gekennzeichnet. Eine Übersicht über die Twitter Plugins und deren Aussehen finden Sie hier: <br />
          <a href="https://about.twitter.com/en_us/company/brand-resources.html">https://about.twitter.com/en_us/company/brand-resources.html</a>
        </p>
        <p>
          Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enthält, stellt Ihr Browser
          eine direkte Verbindung zu den Servern von Twitter her. Der Inhalt des Plugins wird von Twitter direkt
          an Ihren Browser übermittelt und in die Seite eingebunden. Durch die Einbindung erhält Twitter die
          Information, dass Ihr Browser die entsprechende Seite unseres Webauftritts aufgerufen hat, auch wenn
          Sie kein Profil bei Twitter besitzen oder gerade nicht bei Twitter eingeloggt sind. Diese Information
          (einschließlich Ihrer IP-Adresse) wird von Ihrem Browser direkt an einen Server von Twitter in die USA
          übermittelt und dort gespeichert. Sind Sie bei Twitter eingeloggt, kann Twitter den Besuch unserer Website
          Ihrem Twitter-Account unmittelbar zuordnen. Wenn Sie mit den Plugins interagieren, zum Beispiel den „Twittern“-Button
          betätigen, wird die entsprechende Information ebenfalls direkt an einen Server von Twitter übermittelt und dort
          gespeichert. Die Informationen werden außerdem auf Ihrem Twitter-Account veröffentlicht und dort Ihren Kontakten angezeigt.
        </p>
        <p>
          Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Twitter sowie
          Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte
          den Datenschutzhinweisen von Twitter: <a href="https://twitter.com/privacy">https://twitter.com/privacy</a>
        </p>
        <p className="mb-4">
          Wenn Sie nicht möchten, dass Twitter die über unseren Webauftritt gesammelten Daten unmittelbar Ihrem
          Twitter-Account zuordnet, müssen Sie sich vor Ihrem Besuch unserer Website bei Twitter ausloggen. Sie
          können das Laden der Twitter Plugins auch mit Add-Ons für Ihren Browser komplett verhindern, z. B. mit
          dem Skript-Blocker „NoScript“ (<a href="http://noscript.net/">http://noscript.net/</a>).
        </p>
        <p>
          <span className="fw-bold">d) AddThis</span>
        </p>
        <p>
          Auf unserer Website werden ebenfalls Social Plugins („Plugins“) des Bookmarking-Dienstes AddThis
          verwendet, der von der AddThis LLC, Inc. 8000 Westpark Drive, Suite 625, McLean, VA 2210, USA
          („AddThis“) betrieben wird. Die Plugins sind meist mit einem AddThis-Logo beispielsweise in Form
          eines weißen Pluszeichens auf orangefarbenen Grund gekennzeichnet. Eine Übersicht über die AddThis
          Plugins und deren Aussehen finden Sie hier: <br />
          <a href="https://www.addthis.com/get/sharing">https://www.addthis.com/get/sharing</a>
        </p>
        <p>
          Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enthält, stellt Ihr Browser
          eine direkte Verbindung zu den Servern von AddThis her. Der Inhalt des Plugins wird von AddThis direkt
          an Ihren Browser übermittelt und in die Seite eingebunden. Durch die Einbindung erhält AddThis die Information,
          dass Ihr Browser die entsprechende Seite unseres Webauftritts aufgerufen hat und speichert zur Identifikation
          Ihres Browsers ein Cookie auf Ihrem Endgerät. Diese Information (einschließlich Ihrer IP-Adresse) wird von
          Ihrem Browser direkt an einen Server von AddThis in die USA übermittelt und dort gespeichert. AddThis verwendet
          die Daten zur Erstellung anonymisierter Nutzerprofile, die als Grundlage für eine personalisierte und
          interessenbezogene Werbeansprache der Besucher von Internetseiten mit AddThis-Plugins dient.
        </p>
        <p>
          Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch
          AddThis entnehmen Sie bitte den Datenschutzhinweisen von AddThis:
          <a href="http://www.addthis.com/privacy/privacy-policy">http://www.addthis.com/privacy/privacy-policy</a>
        </p>
        <p>
          Wenn Sie der Datenerhebung durch AddThis für die Zukunft widersprechen möchten, können Sie ein
          sogenanntes Opt-Out-Cookie setzen, das Sie unter folgendem Link herunterladen können:
          <a href="http://www.addthis.com/privacy/opt-out">http://www.addthis.com/privacy/opt-out</a>
        </p>
        <p>
          Sie können das Laden der AddThis Plugins auch mit Add-Ons für Ihren Browser komplett verhindern,
          z. B. mit dem Skript-Blocker „NoScript“ (<a href="http://noscript.net/">http://noscript.net/</a>).
        </p>
        <h4>[12. Google Analytics]</h4>
        <p>
          Diese Website nutzt den Dienst „Google Analytics“, welcher von der Google Inc.
          (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) angeboten wird, zur Analyse
          der Websitebenutzung durch Nutzer. Der Dienst verwendet „Cookies“ – Textdateien, welche
          auf Ihrem Endgerät gespeichert werden. Die durch die Cookies gesammelten Informationen
          werden im Regelfall an einen Google-Server in den USA gesandt und dort gespeichert.
        </p>
        <p>
          Auf dieser Website greift die IP-Anonymisierung. Die IP-Adresse der Nutzer wird
          innerhalb der Mitgliedsstaaten der EU und des Europäischen Wirtschaftsraum gekürzt.
          Durch diese Kürzung entfällt der Personenbezug Ihrer IP-Adresse. Im Rahmen der
          Vereinbarung zur Auftragsdatenvereinbarung, welche die Websitebetreiber mit der
          Google Inc. geschlossen haben, erstellt diese mithilfe der gesammelten Informationen
          eine Auswertung der Websitenutzung und der Websiteaktivität und erbringt mit der
          Internetnutzung verbundene Dienstleistungen.
        </p>
        <p>
          Sie haben die Möglichkeit, die Speicherung des Cookies auf Ihrem Gerät zu verhindern,
          indem Sie in Ihrem Browser entsprechende Einstellungen vornehmen. Es ist nicht gewährleistet,
          dass Sie auf alle Funktionen dieser Website ohne Einschränkungen zugreifen können,
          wenn Ihr Browser keine Cookies zulässt.
        </p>
        <p>
          Weiterhin können Sie durch ein Browser-Plugin verhindern, dass die durch Cookies gesammelten
          Informationen (inklusive Ihrer IP-Adresse) an die Google Inc. gesendet und von der Google Inc.
          genutzt werden. Folgender Link führt Sie zu dem entsprechenden Plugin:
          <a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>
        </p>
        <p>
          <a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>
        </p>
        <p>
          Hier finden Sie weitere Informationen zur Datennutzung durch die Google Inc.:
          <a href="https://support.google.com/analytics/answer/6004245?hl=de">https://support.google.com/analytics/answer/6004245?hl=de</a>
        </p>
      </div>
    </div>
  );
};

export default DataProtection;

import React from "react";

const FORM_ENDPOINT = "https://www.natuco.bio/api/contact"; // TODO - fill on the later step

const ContactForm = (props) => {
  if (props.success) {
    return (
      <div className="mt-4">
        <h2>Vielen Dank!</h2>
        <div>Wir melden uns.</div>
      </div>
    );
  }

  return (
    <form
      id="contactForm"
      action={FORM_ENDPOINT}
      method="POST"
    >
      <div className="d-flex flex-column w-100">
        <div className="p-1">
          <select name="salutation" required>
            <option>Frau</option>
            <option>Herr</option>
            <option>Divers</option>
          </select>
        </div>
        <div className="inputRow p-1 d-flex flex-row">
          <div className="flex-grow-1">
            <input
              type="text"
              placeholder="Nachname"
              name="lastname"
              required
            />
          </div>
          <div className="flex-grow-1">
            <input type="text" placeholder="Name" name="firstname" required />
          </div>
        </div>
        <div className="inputRow p-1 d-flex flex-row">
          <div className="flex-grow-1">
            <input type="email" placeholder="E-Mail" name="email" required />
          </div>
          <div className="flex-grow-1">
            <input
              type="text"
              placeholder="Telefonnummer"
              name="phone"
              required
            />
          </div>
        </div>
        <div className="p-1 d-flex flex-row">
          <input
            type="text"
            placeholder="Land/PLZ/Ort"
            name="location"
            required
          />
        </div>
        <div className="p-1 d-flex flex-row">
          <input type="text" placeholder="Betreff" name="subject" required />
        </div>
        <div className="p-1 d-flex flex-row">
          <textarea placeholder="Deine Nachricht" name="message" required />
        </div>
        <div className="p-1 d-flex flex-row align-items-center justify-content-start">
          <input type="checkbox" key={"privacy"} required></input>
          <span>
            Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur
            Beantwortung meiner Anfrage verarbeitet und gespeichert werden.
            Hinweis: Du kannst Deine Einwilligung für die Zukunft per E-Mail an
            info@natuco.bio widerrufen. Detaillierte Informationen zum Umgang
            mit Nutzerdaten findest Du in unserer Datenschutzerklärung.
          </span>
        </div>
        <div className="p-1 d-flex flex-row justify-content-center">
          <button type="submit"> Kontaktformular absenden </button>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;

import React from 'react';
import ContactForm from './ContactForm';

const Contact = (props) => {
  return (
    <div className="content-page container flex-grow-1">
      <div className="row my-5">
        <h1>Kontakt</h1>
        <hr className="mb-5" />
        <p>
          Du hast noch Fragen zu unseren NATUCO-Produkten? Dann melde Dich gern
          bei uns. Wir freuen uns auf Deine Fragen und Anregungen.
        </p>
        <ContactForm success={props.success}></ContactForm>
        <h4>HÜGLI NAHRUNGSMITTEL GMBH</h4>
        <br />
        <p>Güttinger Strasse 23</p>
        <br />
        <p>78315 Radolfzell</p>
        <br />
        <br />
        <p>
          <span className="fw-bold">Tel.:</span>
          <a href="tel:+4977328071"> +49 (0) 7732 / 807-1</a>
        </p>
        <br />
        <p>
          <span className="fw-bold">Fax:</span> +49 (0) 7732 / 807-201
        </p>
        <br />
        <p>
          <span className="fw-bold">E-Mail-Adresse:</span>{" "}
          <a href="mailto:info@natuco.bio">info@natuco.bio</a>
        </p>
      </div>
    </div>
  );
};

export default Contact;

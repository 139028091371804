import React from 'react';
import './Slider.scss';

const SliderTile = ({ productContent }) => {
  return (
    <div className="slider-tile d-flex flex-column">
      <img
        src={require(`../../assets/images/products/${productContent.img}`)}
        alt={productContent.alt}
      />

      <span className="slider-headline text-center mb-2">
        {productContent.title}
      </span>
      <p className="slider-text text-center mx-3">
        {productContent.description}
      </p>
    </div>
  );
};

export default SliderTile;

import React, { useCallback, useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import './BurgerMenu.scss';

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('click', handleClose);
      return () => {
        window.removeEventListener('click', handleClose);
      };
    }
  }, [isOpen, handleClose]);

  return (
    <div className={'burger-menu-wrapper mt-3' + (isOpen ? ' is-open' : '')}>
      <div className="burger-menu-button" onClick={toggleOpen}>
        <div className="burger-bar"></div>
        <div className="burger-bar"></div>
        <div className="burger-bar"></div>
      </div>
      <div className={'burger-menu-menu-wrapper' + (isOpen ? ' is-open' : '')}>
        <div className={'burger-menu-menu'}>
          <img
            className="close-button"
            src={require('../../assets/images/Kreuz.png')}
            alt="Schließen-Icon"
            onClick={toggleOpen}
          />
          <ul>
            <li>
              <nav>
                <HashLink
                  to="/#produkte"
                  scroll={(el) => {
                    setTimeout(() => {
                      el.scrollIntoView({ behavior: 'auto', block: 'end' });
                    }, 100);
                  }}
                >
                  Produkte
                </HashLink>
              </nav>
            </li>
            <li>
              <nav>
                <a href="/kontakt">Kontakt</a>
              </nav>
            </li>
            <li>
              <nav>
                <a href="/datenschutz">Datenschutz</a>
              </nav>
            </li>
            <li>
              <nav>
                <a href="/impressum">Impressum</a>
              </nav>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BurgerMenu;

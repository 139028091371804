import React, { useMemo } from 'react';

import Slider from 'react-slick';
import prevArrow from '../../assets/images/Pfeil_links.png';
import nextArrow from '../../assets/images/Pfeil_rechts.png';

import { productConfig } from '../../tileConfig';
import SliderTile from './SliderTile';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <img src={nextArrow} alt="Weiter" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <img src={prevArrow} alt="Zurück" />
    </div>
  );
}

const ProductSlider = () => {
  const settings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      lazyLoad: 'ondemand',
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }),
    []
  );

  return (
    <div id="produkte">
      <Slider className="mb-5 mx-5" {...settings}>
        {productConfig.map((p, i) => {
          return <SliderTile key={i} productContent={p} index={i} />;
        })}
      </Slider>
    </div>
  );
};

export default ProductSlider;

import React, { useState, useCallback } from 'react';

import { tileConfig } from '../tileConfig';
import InfoTile from '../components/InfoTile/InfoTile';
import ProductSlider from '../components/Slider/ProductSlider';

function Home() {
  const [activeTile, setActiveTile] = useState(0);

  const handleTileClick = useCallback((clickedIndex) => {
    setActiveTile((prev) => {
      if (prev === clickedIndex) {
        return undefined;
      }
      return clickedIndex;
    });
  }, []);

  return (
    <div className="container mt-5">
      <div className="row overflow-hidden">
        {tileConfig.map((tc, i) => {
          return (
            <InfoTile
              key={i}
              isActive={activeTile === i}
              tileContent={tc}
              index={i}
              onClick={handleTileClick}
            />
          );
        })}
      </div>
      <div className="row"></div>
      <div className="text-center mt-5 mb-5" id="slider-headline">
        <h1>Was koch' ich heute?</h1>
      </div>
      <ProductSlider />
    </div>
  );
}

export default Home;
